<template>
  <div>
    <template v-if="successful">
      <img src="../assets/images/success.svg" />
      <h1>Whoop whoop!</h1>
      <p>We've successfully added your card as a recurring payment method.</p>
    </template>
    <template v-if="failed">
      <img src="../assets/images/error.svg" />
      <h1>Something went wrong</h1>
      <p>Sorry. We were unable to add your card.</p>
    </template>
  </div>
</template>

<script>
export default {
  states: ["successful", "failed"],
  computed: {
    paymentData() {
      return this.$route.query;
    },
  },
  mounted() {
    this.currentState = this.paymentData.status;
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 3.6rem;
  margin-bottom: 2.4rem;
}
</style>